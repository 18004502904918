/* .navbar-default .navbar-nav > .active > a,
.navbar-default .navbar-nav > .active > a:hover,
.navbar-default .navbar-nav > .active > a:focus {
  color: #333333;
  background-color: transparent;
} */
:root {
  zoom: 85%;
  --theme-blue: #00205b;
  --theme-yellow: #fae100;
}
.navbar .navbar-nav .nav-item a.nav-link {
  color: #ffffff;
  background-color: transparent;
}

span.input-group-text,
input.input-group-input,
select.input-group-input,
select.input-group-input > option {
  font-size: 1.1rem;
  font-weight: 400;
  border-radius: 0;
  color: var(--theme-blue);
}
.standard-table.table tbody td > span.badge {
  text-transform: uppercase;
  font-size: 1.3rem;
  border-radius: 2px;
  font-weight: 400;
  padding: 4px 10px;
}
.standard-table.table tbody td > span.badge.badge-off {
  background-color: var(--theme-blue);
}
.standard-table.table tbody td > span.badge.badge-on {
  background-color: #3cb371;
}
.standard-table.table tbody td,
.standard-table.table thead > tr > th {
  font-size: 1.6rem;
  font-weight: 500;
}
.standard-table.table tbody td {
  color: var(--theme-blue);
}

.standard-table.table thead > tr > th {
  text-transform: capitalize;
}
.btn.btn-primary {
  font-size: 1.5rem !important;
  font-weight: 500 !important;
  letter-spacing: 1px;
  color: #fff;
  background-color: var(--theme-blue);
  border-color: var(--theme-blue);
  border-radius: 4px;
  padding: 5px 25px;
}

.navbar .navbar-nav .nav-item a.nav-link.active-nav-link {
  background-color: whitesmoke;
  color: var(--theme-blue);
  font-weight: 500;
}

#softphoneOnCall .active-call-line {
  margin: 10px 2px;
  width: 90%;
}
#softphoneOnCall .active-call-line table {
  min-width: 100%;
}
#softphoneOnCall .active-call-line table td {
  padding: 5px 0;
}

#softphoneOnCall .active-call-line table .line-action {
  width: 100%;
  display: flex;
  flex-flow: row;
  justify-content: space-between;
  align-content: center;
}

#softphoneOnCall .active-call-line table .line-action .line-action-info {
  font-size: 15px;
  display: flex;
  flex-flow: row wrap;
  word-break: break-all;
  margin-right: 3px;
}
